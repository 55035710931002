@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

i:hover {
  cursor: pointer;
}
